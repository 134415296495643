
@import url('https://fonts.cdnfonts.com/css/baby-blocks');

                

.hero {
  height: 100vh;
  width: 90vw !important;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}

#play {
  margin-top: 75px;
}

.word,
.capital-word {
  font-family: "Baby Blocks", sans-serif;
  perspective: 1000px;
  margin-top: 110px;
  text-align: center;
  opacity: 1;
}

.word {
  margin-top: 200px;
}

.word span,
.capital-word span {
  cursor: pointer;
  display: inline-block;
  font-size: 100px;
  user-select: none;
  line-height: .8;
  margin-right: 15px;
  opacity: 1;
}



@media (max-width: 1024px) {

  .word span, .capital-word span {
    font-size: 60px !important;
  }
  .word {
    margin-top: 35vh !important;
   
  }
  .capital-word {
    margin-top: 80px;
  }

}


@media (max-width: 767px) {
  .word {
    margin-top: 35vh !important;
  }

  .capital-word {
    margin-top: 80px;
  }

  .word span,
  .capital-word span {

    font-size: 30px !important;
    margin-right: 5px;
  }

  p {
    font-size: 10px;
    margin-top: 50px !important;
  }

  @keyframes shrinkjump {

    10%,
    35% {
      transform: scale(1, .2) translate(0, 0);
    }

    45%,
    50% {
      transform: scale(1) translate(0, -15px);
    }

    80% {
      transform: scale(1) translate(0, 0);
    }
  }

}

#blocks {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: black;
}



@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.fade-in-me {
  animation: fadeIn 2s ease-in-out;
}

.fade-in-me:nth-child(odd) {
  animation: fadeIn 6s ease-in-out;
}

.fade-in.show {
  opacity: 1;
}

.word span:nth-child(1).active,
.capital-word span:nth-child(1).active {
  animation: balance 1.5s ease-out;
  transform-origin: bottom left;
  color: #23e8e8;
  opacity: 1 !important;
}

.word span:nth-child(1),
.capital-word span:nth-child(1) {
  color: #23e8e8;
  opacity: 1 !important;
}

@keyframes balance {

  0%,
  100% {
    transform: rotate(0deg);
  }

  30%,
  60% {
    transform: rotate(-45deg);
  }
}

.word span:nth-child(2).active,
.capital-word span:nth-child(2).active,
.capital-word span:nth-child(6).active {
  animation: shrinkjump 1s ease-in-out;
  transform-origin: bottom center;
  color: greenyellow;
  opacity: 1;
}

.word span:nth-child(2),
.capital-word span:nth-child(2),
.capital-word span:nth-child(6) {
  color: greenyellow;
  opacity: 1 !important;
}

@keyframes shrinkjump {

  10%,
  35% {
    transform: scale(1, .2) translate(0, 0);
  }

  45%,
  50% {
    transform: scale(1) translate(0, -50px);
  }

  80% {
    transform: scale(1) translate(0, 0);
  }
}

.word span:nth-child(3).active,
.capital-word span:nth-child(3).active {
  animation: falling 2s ease-out;
  transform-origin: bottom center;
  color: white;
}

.word span:nth-child(3),
.capital-word span:nth-child(3) {
  color: white;
  opacity: 1 !important;
}

@keyframes falling {
  12% {
    transform: rotateX(240deg);
  }

  24% {
    transform: rotateX(150deg);
  }

  36% {
    transform: rotateX(200deg);
  }

  48% {
    transform: rotateX(175deg);
  }

  60%,
  85% {
    transform: rotateX(180deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

.word span:nth-child(4).active {
  animation: rotate 1s ease-out;
  color: greenyellow;
}

.capital-word span:nth-child(4).active {
  animation: rotate 1s ease-out;
  color: #23e8e8;
}

.word span:nth-child(4) {
  color: greenyellow;
  opacity: 1 !important;
}

.capital-word span:nth-child(4) {
  color: #23e8e8;;
  opacity: 1 !important;
}
@keyframes rotate {

  20%,
  80% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

.word span:nth-child(5).active {
  animation: balance-opp 1.5s linear;
  color: #23e8e8;
}

.capital-word span:nth-child(5).active {
  animation: falling 2s ease-out;
  transform-origin: bottom center;
  color: white;
}

.word span:nth-child(5) {
  color: #23e8e8;
  opacity: 1 !important;
}

.capital-word span:nth-child(5) {
  color: white;
  opacity: 1 !important;
}

.capital-word span:nth-child(7) {
  color: #23e8e8;
  opacity: 1 !important;
}

.capital-word span:nth-child(7).active {
  animation: balance-opp 1.5s linear;
  color: #23e8e8;
  opacity: 1 !important;
}

@keyframes balance-opp {

  0%,
  100% {
    transform: rotate(0deg);
  }

  30%,
  60% {
    transform: rotate(45deg);
  }
}

p {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  margin-top: 50px;
  color: crimson;
}