@import url('https://fonts.cdnfonts.com/css/baby-blocks');
@import url('https://fonts.cdnfonts.com/css/fredoka');
form {
  display: flex;
  flex-direction: column;
}
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block; 
  margin-bottom: 5px;
  font-family:'Fredoka', sans-serif;
}


.form-group input,
.form-group textarea {  
  width: 40vw;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family:'Fredoka', sans-serif !important;
}

input {
  color: black;
  font-size: 12px;
}
.error {
  padding: 0px;
  width: 200px;
  display: block;
  margin-top: 10px;
  font-weight: 600;
  text-align: center;
  font-family:'Fredoka', sans-serif;
  color: red;
}
.form-group label {
  color: black;
  font-weight: 600;
}

button {
  background-color: #3b843e;
  font-family:'Fredoka', sans-serif;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

.contact {
  width: 80%;
  
  h1 {
    text-align: center;
    margin-top: 120px;
    margin-bottom: 40px;
    font-family: "Baby Blocks", sans-serif !important;
    font-size: 80px;
    color: #23e8e8;
    font-weight: 300;
  }

  

  h3 {
    font-size: 30px;
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family:'Fredoka', sans-serif;
  }

  p {
    margin-top: 5px !important;
    margin-bottom: 30px;
    margin: 0px 68px 30px 68px;
    color: black;
    font-family:'Fredoka', sans-serif;
  }
}

form {
  background-color: rgb(243 138 69);
  width: 60%;
  border-radius: 10px;
  border: 5px solid rgba(28, 27, 27, 0.315);
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  
}

#contact-p {
  font-size: 14px;
  color: black;
  font-weight: 600;
  margin-bottom: 30px;
  width: 90%;
  font-family:'Fredoka', sans-serif;

}

#thanks {
  text-align: center;
    color: crimson;
    font-size: 30px;
    padding-top: 40px;
    font-family:'Fredoka', sans-serif;
}

@media (max-width: 950px) {
  #thanks {
    margin-left: 0px !important; 
    margin-right: 0px !important;
    margin-bottom: 70px;
  }

  form {
    width: 90%;
  }

  .form-group input,
.form-group textarea {
  width: 60vw !important;

}
#contact-p {
  width: 91%;
}
  .about {
    p {
      font-size: 20px;
    }
    #big-text {
      font-size: 22px;
    }
  }
}


@media (max-width: 767px) {
  h1 {
    font-size: 25px !important;
    margin-top: 40px !important;
    margin-bottom: 30px !important;
    font-family:'Fredoka', sans-serif !important;
  }
  .contact {
    width: 100vw;
  }
  .nambr-form {
    width: 90vw;
  }

  .form-group input,
.form-group textarea {
  width: 70vw !important;
  font-family:'Fredoka', sans-serif !important;
  font-size: 12px;
  color:black;
  border: 1px solid #ccc;
  border-radius: 4px;
}
  #contact-p {
  width: 90% !important;
  }

  #thanks {
    text-align: center;
      margin-left: 0px !important;
      color: crimson;
      font-size: 20px !important;
      padding-top: 20px;
  }
}


#T {
  color: #23e8e8;
}

#T2 {
  margin-left: 10px;
}