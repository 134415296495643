@import url('./components/navbar.css');
@import url('./components/hero.css');
@import url('./components/about.css');
@import url('./components/services.css');
@import url('./components/contact.css');
@import url('https://fonts.googleapis.com/css?family=Anton|Roboto');

* {
  box-sizing: border-box;
  margin: 0 auto;

}

body {
  background-color: rgb(233, 136, 72);
  color: #fff;
  font-family: 'Roboto', sans-serif;
  flex-direction: row;
  height: 100vh;
  width: 100%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 2s forwards;
}


footer {
  font-size: 10px;
  text-align: center;
  margin-top: 40px;
  padding-bottom: 10px;
  color: black;
  font-weight: 600;
  font-family: monospace;
}

footer a {
  color: black;
  text-decoration: none;
}

footer a:hover {
  color: white;

}

