
@import url('https://fonts.cdnfonts.com/css/baby-blocks');
header {
    box-shadow: 1px 1px 10px black;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 0 0 5px 5px;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 1px 1px 10px black;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0 0 5px 5px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  height:60px;
}

.navbar-nav {
  display: flex;
}

.nav-link {
  text-align: center;
  color: black;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 600;
  font-family:'Fredoka', sans-serif;
  
}

#nambr {
  font-family: "Baby Blocks", sans-serif;
  font-size: 22px;
  font-weight: 300 !important;
}

.nav-active {
  color: rgb(233, 136, 72)!important;
}

a {
  text-decoration: none;
}

svg:hover {
  fill: rgb(233, 136, 72);
}


.nav-link:hover {
  color: rgb(233, 136, 72);
}

