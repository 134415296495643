@import url('https://fonts.cdnfonts.com/css/baby-blocks');
@import url('https://fonts.cdnfonts.com/css/fredoka');

                

.services {
  width: 80%;
}

.services h1 {
  text-align: center;
  margin-top: 120px;
  margin-bottom: 40px;
  font-family: "Baby Blocks", sans-serif !important;
  font-size: 80px;
  color: #23e8e8;
  font-weight: 300;
}

.services h2 {
  text-align: center;
  margin-bottom: 10px;
  color: white;
}

.wrapped-services {
  display: flex;
  gap: 20px;
}

.business, .individual {
  width: 45%;
  background-color: rgb(243 138 69);
  border-radius: 10px;
  border: 5px solid rgba(28, 27, 27, 0.315);
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-family: 'Fredoka', sans-serif;
  font-weight: 400; 
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

li {
  padding: 10px 10px 10px 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 18px;
  color: black;
}

li:last-child {
  border-bottom: none;
}

@media (max-width: 767px) {
  .wrapped-services {
    flex-direction: column;
    gap: 10px;
  }
  
  .services {
    width: 95%;
  }

  .services h1 {
    font-size: 25px !important;
    margin-top: 40px !important;
    margin-bottom: 30px !important;
  }

  .services h2 {
    margin-bottom: 0px !important;
    margin-top: 0px !important;

  }

  .business, .individual {
    width: 95% !important;
  }
  
  li {
    padding: 10px 0;
    font-size: 16px;
  }
}

@keyframes staggeredFadeIn {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0); 
  }
}

.staggeredAnimate {
  animation: staggeredFadeIn 1s ease forwards;
  opacity: 0;
}

.staggeredAnimate.delay-0 {
  animation-delay: 0s;
}

.staggeredAnimate.delay-1 {
  animation-delay: 0.5s;
}

.staggeredAnimate.delay-2 {
  animation-delay: 1s;
}

.staggeredAnimate.delay-3 {
  animation-delay: 1.5s;
}

.staggeredAnimate.delay-4 {
  animation-delay: 2s;
}

.staggeredAnimate.delay-5 {
  animation-delay: 2.5s;
}

.staggeredAnimate.delay-6 {
  animation-delay: 3s;
}

.staggeredAnimate.delay-7 {
  animation-delay: 3.5s;
}


.staggeredAnimate.delay-8 {
  animation-delay: 4s;
}
.staggeredAnimate.delay-9 {
  animation-delay: 4.5s;
}
.hide {
  display: none;
}

@media (max-width: 950px) {
  .services {
    width: 95%;
  }
}

#S {
  margin-left: 10px;
}
.forth-letter, #i {
  color: #23e8e8;
}