

@import url('https://fonts.cdnfonts.com/css/baby-blocks');

@import url('https://fonts.cdnfonts.com/css/fredoka');
.about-main {
  h1 {
    
    font-family: "Baby Blocks", sans-serif !important;
    text-align: center;
    padding-top: 50px;
    font-size: 80px;
    color: #23e8e8;
    font-weight: 300;
    margin-bottom: 40px;
  }
}
.about {
  width: 70%;

  background-color: rgb(243 138 69);
  border-radius: 10px;
  border: 5px solid rgba(28, 27, 27, 0.315);
  padding-bottom: 50px;
  pointer-events: none !important;


  p {
    color: black;
    font-size: 18px;
    font-family:'Fredoka', sans-serif;
    font-weight: 400;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 40px;
  }

  

  h2 {
    font-family: 'Fredoka', sans-serif;
    text-align: center;
    font-size: 25px;
    color: white;
    margin-top: 40px;
  
  }
}



#big-text {
  margin-top: 5px;
  font-size: 20px;
  font-weight: 600;
}

#bold-text {
  font-weight: 600;
}

.second-letter {
  color: white;
  margin-left:10px;
}

.third-letter {
  color: greenyellow;
  margin-left:10px;
}

.forth-letter {
  color: white;
  margin-left:10px;
}

.fifth-letter {
  color: white;
  margin-left:10px;
}

#T {
  margin-left: 10px !important;
}

.image-container {
  display: flex;
  justify-content: center;
}

#joe {
  height: 175px;
  border-radius: 100px;
  margin-top: 40px;
  filter: grayscale(100%);
  border: 3px solid silver;

}

@media (max-width: 767px) {
  .about-main {
    h1 {

      text-align: center;
      padding-top: 50px;
      font-size: 25px !important;
      color: aqua;
      font-weight: 300;
      margin-bottom: 35px;
    margin-bottom: 30px !important;
    }
  }
  
  .image-container {
    display: flex;
    justify-content: center;
  }
  
  #joe {
    height: 130px;
    border-radius: 100px;
    margin-top: 10px;
    filter: grayscale(100%);
    border: 3px solid silver;
  
  }
  .about {
    width: 95%;
    padding-bottom: 20px;
  }
  
  h1 {
    padding-top: 30px !important;
    font-size: 30px !important;
    color: aqua !important;
    font-weight: 300;
    margin-bottom: 35px;
  }
 
  h2 {
    text-align: center;
    font-size: 20px !important;
    margin-top: 20px !important;
    margin-bottom: 10px !important;
    margin-left: 10px;
    margin-right: 10px;
    color:black;
  
  }

  #about {
    color: black;
    font-size: 16px !important;
    font-family:'Fredoka', sans-serif;
    font-weight: 400;
    margin-top: 25px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  #big-text {
    margin-top: 5px;
    font-family:'Fredoka',sans-serif;
    font-size: 20px !important;
    font-weight: 600;
    color: black;
  }

}

@media (max-width: 950px) {
  h1 {
    font-size: 40px !important;
  }
  .about {
    width: 90%;
  }
}
